
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const wayList = [
      {
        img: "kefu1.png",
        name: "免费电话",
      },
      // {
      //   img: "kefu2.png",
      //   name: "微信咨询",
      // },
      {
        img: "kefu3.png",
        name: "客服咨询",
      },
    ];
    const wayIndex = ref(-1);
    let timer: ReturnType<typeof setTimeout>;
    const kefuWay = (index: number) => {
      // 鼠标进入元素显示弹窗，清除定时器
      wayIndex.value = index;
      clearTimeout(timer);
      if (index !== -1 && wayIndex.value !== index) {
        timer = setTimeout(() => {
          wayIndex.value = -1;
        }, 500);
      }
    };
    const hideDetail = () => {
      clearTimeout(timer);
      if (wayIndex.value !== -1) {
        timer = setTimeout(() => {
          wayIndex.value = -1;
        }, 500);
      }
    };

    // 当鼠标在弹窗中时，清除之前设置的定时器，以保证弹窗一直显示
    const clearTimer = () => {
      clearTimeout(timer);
    };

    const goToLink = () => {
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc2f57dba70b949766";
    };

    const goWayToLink =(index: any)=>{
      console.log(index,1111111111)
      if(index == 1){
        window.location.href =
        "https://work.weixin.qq.com/kfid/kfc2f57dba70b949766";
      }
    }

    return {
      wayList,
      wayIndex,
      kefuWay,
      hideDetail,
      clearTimer,
      goToLink,
      goWayToLink
    };
  },
});
