
import { ref, onMounted, nextTick } from "vue";
import { onUnmounted } from "@vue/runtime-core";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    // 定义矩形元素的初始状态
    const items = ref([
      {
        width: "50%",
        marginLeft: "0px",
        clicked: true,
        title: "婚嫁",
        entitle: "MARRIAGE",
        description: "助力传统婚嫁行业\n品牌服务升级与数字化转型",
      },
      {
        width: "calc(50% / 2 - 5px)",
        marginLeft: "5px",
        clicked: false,
        title: "成人教育",
        entitle: "ADULT EDUCATION",
        description: "落实教育创新改革\n科学提质减负",
      },
      {
        width: "calc(50% / 2 - 5px)",
        marginLeft: "5px",
        clicked: false,
        title: "健康",
        entitle: "HRALTH",
        description: "医学大数据赋能健康\n助力产业模式升级",
      },
    ]);

    // 点击矩形元素时切换状态
    const toggleClick = (index: number) => {
      items.value.forEach((item, i) => {
        if (i === index) {
          item.clicked = true;
          item.width = "50%";
          item.marginLeft = "5px";
        } else {
          item.clicked = false;
          item.width = "calc(50% / 2 - 5px)";
          item.marginLeft = "5px";
        }
      });
    };

    // 在组件挂载时，调整矩形元素的宽度和间距，使其适应容器的宽度
    const adjustItems = () => {
      const container = document.querySelector(".container");
      if (!container) return;
      const containerWidth = container.clientWidth;
      const itemWidth = containerWidth / 2;
      const itemMargin = (containerWidth - itemWidth * 5) / 2;
      items.value.forEach(
        (item: { width: string; marginLeft: string }, index: number) => {
          item.width = index === 0 ? `${itemWidth}px` : `${itemWidth / 2}px`;
          item.marginLeft = index === 0 ? "0px" : `${itemMargin}px`;
        }
      );
    };

    // 在组件挂载后和窗口大小变化时，调整矩形元素的宽度和间距，使其适应容器的宽度
    onMounted(() => {
      nextTick(() => {
        adjustItems();
      });
      window.addEventListener("resize", adjustItems);
    });

    // 在组件卸载时，移除窗口大小变化事件的监听器
    onUnmounted(() => {
      window.removeEventListener("resize", adjustItems);
    });

    return { items, toggleClick };
  },
};
